import { Component, Input, Output, EventEmitter, ElementRef, Renderer2, ViewEncapsulation, ViewChild, OnChanges, PLATFORM_ID, Inject, SecurityContext } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpHeaders } from '@angular/common/http';
import { ModalController, NavParams, AlertController, Platform } from '@ionic/angular';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { UserService } from 'src/app/core/services/user.service';
import { TaskService, TaskModel, TaskHistoryModel } from 'src/app/core/services/tasks.service';
import { N7ModalStatusComponent } from 'src/app/components/n7-modal-status/n7-modal-status.component';
import { N7ModalZoneComponent } from 'src/app/components/n7-modal-zone/n7-modal-zone.component';
import { N7ModalInterventTypeComponent } from 'src/app/components/n7-modal-intervent-type/n7-modal-intervent-type.component';
import { N7ModalConfirmComponent } from 'src/app/components/n7-modal-confirm/n7-modal-confirm.component';

import * as _ from 'lodash';
import * as moment from 'moment';
import { N7ModalFormComponent } from '../n7-modal-form/n7-modal-form.component';
import { ProjectService } from 'src/app/core/services/projects.service';

const defaultItem = {};

@Component({
  selector: 'n7-task',
  templateUrl: './n7-task.component.html',
  styleUrls: [
    './n7-task.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class N7TaskComponent implements OnChanges {
  public task: any;
  public taskHistory: any;
  public taskZones: any[];
  public taskTypes: any[];
  public taskUseSuggestions: boolean = false;
  public taskSuggestions: any[];
  public taskTheme: string = 'primary';
  public access_token: string;

  public taskCanChangeStatus: boolean = true;
  public taskShowZone: boolean = true;
  public taskCanSetZone: boolean = true;
  public taskCanChangeZone: boolean = false;
  public taskCanSetType: boolean = true;
  public taskCanChangeType: boolean = false;
  public taskShowPrivacy: boolean = true;
  public taskCanChangePrivacy: boolean = true;
  public taskCanChangeLocation: boolean = true;
  public taskCanBeDeleted: boolean = true;
  public taskCanViewDoc: boolean = true;

  taskCanSetDescription: boolean = false;
  taskCanSetPhotos: boolean = false;

  taskShowResolutionDate: boolean = false;
  taskCanSetResolutionDate: boolean = false;
  taskCanChangeResolutionDate: boolean = false;
  taskShowResolutor: boolean = false;
  taskCanSetResolutor: boolean = false;
  taskCanChangeResolutor: boolean = false;
  taskShowDocumentReference: boolean = false;
  taskCanSetDocumentReference: boolean = false;
  taskCanChangeDocumentReference: boolean = false;

  public taskDescInFocus: boolean = false;

  public taskInfoCollapsed = true;

  public taskCircleIcons: any;

  public isDesktop = false;
  public isTablet = false;
  public isMobile = false;
  public isIos = false;

  constructor(
    public platform: Platform,
    private renderer: Renderer2,
    private modalController: ModalController,
    public alertController: AlertController,
    // private navParams: NavParams,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private taskService: TaskService,
    private projectService: ProjectService,
    // private historyService: HistoryService,
    // private commentService: CommentService,
    // private documentService: DocumentsService,
    private translate: TranslateService
  ) {
    this.taskCircleIcons = {
      draft: '/assets/pins/Draft/Circle.svg',
      submitted: '/assets/pins/Sent/Circle.svg',
      'closed-submitted': '/assets/pins/Sent/Circle.svg',
      accepted: '/assets/pins/Accepted/Circle.svg',
      'closed-accepted': '/assets/pins/Accepted/Circle.svg',
      'closed-denied': '/assets/pins/Closed/Declined/Circle.svg',
      denied: '/assets/pins/Declined/Circle.svg',
      'in progress': '/assets/pins/Progress/Circle.svg',
      'closed-in progress': '/assets/pins/Progress/Circle.svg',
      completed: '/assets/pins/Completed/Circle.svg',
      'closed-completed': '/assets/pins/Closed/Completed/Circle.svg',
      open: '/assets/pins/Remark/Open/Circle.svg',
      'closed-open': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      no_actions: '/assets/pins/Remark/NoActions/Circle.svg',
      'closed-no_actions': '/assets/pins/Remark/NoActions/Closed/Circle.svg',
      local_actions: '/assets/pins/Remark/Local/Circle.svg',
      'closed-local_actions': '/assets/pins/Remark/Local/Closed/Circle.svg',
      total_actions: '/assets/pins/Remark/Total/Circle.svg',
      'closed-total_actions': '/assets/pins/Remark/Total/Closed/Circle.svg',
      monitored: '/assets/pins/Monitored/Circle.svg',
      'closed-monitored': '/assets/pins/Monitored/Circle.svg',
    };

    this.isDesktop = false;
    this.isTablet = false;
    this.isMobile = false;
    this.isIos = false;

    this.platform.ready().then(() => {
      if (this.platform.is('desktop')) {
        this.isDesktop = true;
      }
      if (this.platform.is('tablet')) {
        this.isTablet = true;
      }
      if (this.platform.is('mobile')) {
        this.isMobile = true;
      }
      if (this.platform.is('ios')) {
        this.isIos = true;
      }
    });
  }

  @Input() set item(val: any) {
    this.task = (val !== undefined && val !== null) ? val : null;
  }

  @Input() set history(val: any[]) {
    this.taskHistory = (val !== undefined && val !== null) ? val : null;
  }

  @Input() set zones(val: any[]) {
    this.taskZones = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set types(val: any[]) {
    this.taskTypes = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set useSuggestions(val: boolean) {
    this.taskUseSuggestions = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set suggestions(val: any[]) {
    this.taskSuggestions = (val !== undefined && val !== null) ? val : [];
  }

  @Input() set theme(val: string) {
    this.taskTheme = (val !== undefined && val !== null) ? val : 'primary';
  }

  @Input() set canChangeStatus(val: boolean) {
    this.taskCanChangeStatus = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set showZone(val: boolean) {
    this.taskShowZone = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canSetZone(val: boolean) {
    this.taskCanSetZone = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canChangeZone(val: boolean) {
    this.taskCanChangeZone = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set showPrivacy(val: boolean) {
    this.taskShowPrivacy = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canChangePrivacy(val: boolean) {
    this.taskCanChangePrivacy = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canChangeLocation(val: boolean) {
    this.taskCanChangeLocation = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canBeDeleted(val: boolean) {
    this.taskCanBeDeleted = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canViewDoc(val: boolean) {
    this.taskCanViewDoc = (val !== undefined && val !== null) ? val : true;
  }

  @Input() set canSetDescription(val: boolean) {
    this.taskCanSetDescription = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canSetPhotos(val: boolean) {
    this.taskCanSetPhotos = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set showResolutionDate(val: boolean) {
    this.taskShowResolutionDate = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canSetResolutionDate(val: boolean) {
    this.taskCanSetResolutionDate = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canChangeResolutionDate(val: boolean) {
    this.taskCanChangeResolutionDate = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set showResolutor(val: boolean) {
    this.taskShowResolutor = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canSetResolutor(val: boolean) {
    this.taskCanSetResolutor = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canChangeResolutor(val: boolean) {
    this.taskCanChangeResolutor = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set showDocumentReference(val: boolean) {
    this.taskShowDocumentReference = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canSetDocumentReference(val: boolean) {
    this.taskCanSetDocumentReference = (val !== undefined && val !== null) ? val : false;
  }

  @Input() set canChangeDocumentReference(val: boolean) {
    this.taskCanChangeDocumentReference = (val !== undefined && val !== null) ? val : false;
  }



  @Output() itemClick = new EventEmitter();

  @Output() taskStatusChange = new EventEmitter();

  @Output() taskZoneChange = new EventEmitter();

  @Output() taskTypeChange = new EventEmitter();

  @Output() taskPrivacyChange = new EventEmitter();

  @Output() taskLocationClick = new EventEmitter();

  @Output() taskDescriptionChange = new EventEmitter();

  @Output() taskDetailedImagesChange = new EventEmitter();

  @Output() taskAdditionalImagesChange = new EventEmitter();

  @Output() taskDeleted = new EventEmitter();

  @Output() taskViewDocument = new EventEmitter();

  @Output() taskSuggestionUsed = new EventEmitter();

  @Output() taskResolutionDateChange = new EventEmitter();

  @Output() taskResolutorChange = new EventEmitter();

  @Output() taskDocumentReferenceChange = new EventEmitter();

  ngOnChanges() {
    const self = this;
    setTimeout(() => {
      self.init();
    }, 50);
  }

  async authorize() {
    return new Promise((resolve, reject) => {
      this.userService.getToken().pipe(
        take(1)
      ).subscribe(accessToken => {
        if (!accessToken) {
          this.router.navigateByUrl('auth');
        } else {
          this.access_token = accessToken;
          const headers = new HttpHeaders({ Authorization: 'Bearer ' + this.access_token });
          this.taskService.headers = headers;
          // this.historyService.headers = headers;
          // this.commentService.headers = headers;
          // this.documentService.headers = headers;
        }
        resolve(true);
      }, (error) => {
        reject(error);
      });
    });
  }

  async init() {
    try {
      if (this.task) {
        // console.log('this.task', this.task);
        // this.task.lastupdate_text = this.dateToFromNowDaily(this.task['updated-at']);
        this.task.lastupdate_text = moment(this.task['updated-at']).format('DD.MM.YYYY HH:mm');

        // Author Initials
        if (this.task.author && !this.task.author_initials) {
          let authorSplit = this.task.author.split(' ');
          this.task.author_initials = authorSplit[0].charAt(0).toUpperCase() + authorSplit[1].charAt(0).toUpperCase();
        }

        // Last Editor Initials
        if (this.task.last_editor && !this.task.last_editor_initials) {
          let lastEditorSplit = this.task.last_editor.split(' ');
          this.task.last_editor_initials = lastEditorSplit[0].charAt(0).toUpperCase() + lastEditorSplit[1].charAt(0).toUpperCase();
        }

        if (this.task.user_in_charge && !this.task.user_in_charge_id) {
          this.task.user_in_charge_id = this.task.user_in_charge.id;
        }

        if (this.task.dream_document && !this.task.dream_document_id) {
          this.task.dream_document_id = this.task.dream_document.id;
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  }

  // TODO: Spostare nelle Utilities.
  dateToFromNowDaily(customDate) {
    if (moment(customDate).isValid()) {
      // get from-now for this date
      var fromNow = moment(customDate).fromNow();

      // ensure the date is displayed with today and yesterday
      return moment(customDate).calendar(null, {
        // when the date is closer, specify custom values
        lastWeek: '[Last] dddd [at] HH:mm',
        lastDay: '[Yesterday] [at] HH:mm',
        sameDay: '[Today] [at] HH:mm',
        nextDay: '[Tomorrow] [at] HH:mm',
        nextWeek: 'dddd [at] HH:mm',
        // when the date is further away, use from-now functionality
        sameElse: function () {
          return "[" + fromNow + "]";
        }
      });
    }
    return ' ';
  }

  toggleTaskInfo() {
    this.taskInfoCollapsed = !this.taskInfoCollapsed;
  }

  toggleParentClass(event: any, parentClassName: string, className: string) {
    let parent = event.target.parentNode;
    for (let index = 0; index < 100; index++) {
      const isParentClass = parent.classList.contains(parentClassName);
      if (isParentClass) {
        break;
      }
      else {
        parent = parent.parentNode;
      }
    }
    const hasClass = parent.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(parent, className);
    } else {
      this.renderer.addClass(parent, className);
    }
  }


  async changeStatus($event) {
    // console.log('changeStatus', $event);
    this.presentTaskChangeStatusModal(this.task);
  }

  async changeZone($event) {
    // console.log('changeZone', $event);
    this.presentTaskChangeZoneModal(this.task);
  }

  async changeType($event) {
    // console.log('changeType', $event);
    this.presentTaskChangeTypeModal(this.task);
  }

  async shareTaskInfo($event) {
    // console.log('shareTaskInfo', $event);
    // TODO
  }

  onTaskClick($event) {
    // console.log('event', $event);
    this.itemClick.emit($event);
  }

  onTaskDescriptionBlur(task: TaskModel, description: string) {
    // console.log('blur', task, description);
    this.taskDescInFocus = false;
    this.onStatusDescriptionChange(task, description);
  }

  onTaskDescriptionFocus() {
    // console.log('focus');
    this.taskDescInFocus = true;
  }

  async onStatusDescriptionChange(task: TaskModel, description: string) {
    // console.log('onStatusDescriptionChange');
    if (this.taskDescInFocus == false) {
      this.taskDescriptionChange.emit(description);
    }
  }

  async onStatusDetailedImagesChange(task: TaskModel, detailed_images: any[]) {
    this.taskDetailedImagesChange.emit(detailed_images);
  }

  async onStatusAdditionalImagesChange(task: TaskModel, additional_images: any[]) {
    this.taskAdditionalImagesChange.emit(additional_images);
  }


  async alertConfirm(title, message, callback) {
    const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: [
        {
          text: 'Dismiss',
          handler: () => {
            alert.dismiss();
          }
        },
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
            callback();

          }
        }
      ]
    });
    await alert.present();
  }

  async taskSetPrivacy(taskId: string, privacyFlag: number) {
    const title = '';
    let message = '';
    let flag = 0;

    if (0 === privacyFlag) {
      message = 'Are you sure that you want to set task privacy to private?';
      flag = 1;
    }
    else {
      message = 'Are you sure that you want to set task privacy to public?';
      flag = 0;
    }

    this.alertConfirm(title, message, () => {
      this.taskPrivacyChange.emit(flag);
    });
  }

  async taskDelete(taskId: string) {
    this.presentItemRemoveConfirmModal(taskId, {});
  }

  async presentItemRemoveConfirmModal(taskId: string, ev: any) {
    const modal = await this.modalController.create({
      component: N7ModalConfirmComponent,
      componentProps: {
        titleLabel: 'Delete point',
        message: 'Do you want delete this point?',
        dismissLabel: 'Cancel',
        confirmLabel: 'Yes, delete'
      },
      cssClass: 'modal-confirm'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.confirmed) {
          this.taskDeleted.emit();
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  async presentTaskChangeStatusModal(task) {
    this.task.sectionImage = null;
    const modal = await this.modalController.create({
      component: N7ModalStatusComponent,
      componentProps: {
        task: task
      },
      cssClass: 'modal-task-status auto-height'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.data && data.data.data.confirmed) {
          this.taskStatusChange.emit(data.data.status);
        }
      }
    });

    return await modal.present();
  }

  async presentTaskChangeZoneModal(task) {
    const modal = await this.modalController.create({
      component: N7ModalZoneComponent,
      componentProps: {
        task: task,
        zones: this.taskZones
      },
      cssClass: 'modal-task-status auto-height'
    });

    modal.onDidDismiss().then((data) => {
      // console.log('data', data);
      // data = null;
      if (data !== null) {
        if (data.data && data.data.data && data.data.data.confirmed) {
          this.taskZoneChange.emit(data.data.zone);
        }
      }
    });

    return await modal.present();
  }

  async presentTaskChangeTypeModal(task) {
    const modal = await this.modalController.create({
      component: N7ModalInterventTypeComponent,
      componentProps: {
        task: task,
        interventTypes: this.taskTypes
      },
      cssClass: 'modal-task-status auto-height'
    });

    modal.onDidDismiss().then((data) => {
      // console.log('data', data);
      // data = null;
      if (data !== null) {
        if (data.data && data.data.data && data.data.data.confirmed) {
          // console.log('data confirmed');
          this.taskTypeChange.emit(data.data.interventType);
        }
      }
    });

    return await modal.present();
  }

  async editLocation() {
    this.taskLocationClick.emit()
  }

  async viewDocument(docId) {
    this.taskViewDocument.emit(docId)
  }

  async onTaskSuggestionUsed(task: TaskModel, suggestionId: string) {
    // console.log('onStatusDescriptionChange');
    if (this.taskDescInFocus == false) {
      this.taskSuggestionUsed.emit(suggestionId);
    }
  }

  async editResolutionDate() {
    await this.presentResolutionDateModal();
  }

  async editResolutor() {
    await this.presentResolutorModal();
  }

  async editDocumentReference() {
    await this.presentDocumentReferenceModal();
  }

  private async presentResolutionDateModal(ev: any = null) {
    const dateFormItem = {
      type: 'date-picker',
      label: '',
      title: 'Select date',
      displayFormat: 'YYYY-MM-DD',
      valueFormat: 'YYYY-MM-DD',
      value: '',
      hint: ''
    };
    const modal = await this.modalController.create({
      component: N7ModalFormComponent,
      componentProps: {
        titleLabel: this.translate.instant('i18n.task.property.resolution_date.title', {}),
        formItems: [dateFormItem],
        dismissLabel: 'Save'
      },
      cssClass: 'modal-form'
    });

    modal.onDidDismiss().then((data) => {
      if (data !== null) {
        if (data.data && data.data.formItems && data.data.formItems[0] && data.data.formItems[0].value && data.data.formItems[0].value.length) {
          const itemDate = data.data.formItems[0].value;
          // console.warn('TODO', itemDate);
          this.taskResolutionDateChange.emit(itemDate);
        }
      }
    }).catch((error) => {
      console.log('Error', error);
    });

    return await modal.present();
  }

  private async presentResolutorModal(ev: any = null) {
    // console.log('this.task', this.task);
    this.projectService.getProjectUsers(this.task.project_id).subscribe(async (res) => {
      const options = [];
      res.data.forEach(element => {
        options.push({
          key: element.id,
          label: ((element.attributes.name) + ' ' + (element.attributes.surname) + ' - ' + element.attributes.dream_position),
          searchValues: [element.attributes.name, element.attributes.surname]
        });
      });
      // console.log('this.task.user_in_charge_id', this.task.user_in_charge_id);
      const valueFormItem = {
        type: 'text-picker',
        label: '',
        placeholder: 'Select the user...',
        unit: '',
        value: ((this.task.user_in_charge_id) ? '' + this.task.user_in_charge_id : ''),
        hint: '',
        options
      };
      const modal = await this.modalController.create({
        component: N7ModalFormComponent,
        componentProps: {
          titleLabel: this.translate.instant('i18n.task.property.resolutor.title', {}),
          formItems: [valueFormItem],
          dismissLabel: 'Save'
        },
        cssClass: 'modal-form modal-form-large'
      });

      modal.onDidDismiss().then((data) => {
        if (data !== null) {
          if (data.data && data.data.formItems && data.data.formItems[0]) {
            const itemValue = data.data.formItems[0].value;
            // console.warn('taskResolutorChange', itemValue);
            this.taskResolutorChange.emit(itemValue);
          }
        }
      }).catch((error) => {
        console.log('Error', error);
      });

      return await modal.present();
    });
  }

  private async presentDocumentReferenceModal(ev: any = null) {
    // console.log('this.task', this.task);
    this.projectService.getProjectDreamDocuments(this.task.project_id).subscribe(async (res) => {
      const options = [];
      res.data.forEach(element => {
        options.push({
          key: element.id,
          label: ((element.attributes.wbs_code) + ' - ' + element.attributes.name),
          searchValues: [element.attributes.name, element.attributes.wbs_code]
        });
      });
      // console.log('this.task.dream_document_id', this.task.dream_document_id);
      const valueFormItem = {
        type: 'text-picker',
        label: '',
        placeholder: 'Select the document...',
        unit: '',
        value: ((this.task.dream_document_id) ? '' + this.task.dream_document_id : ''),
        hint: '',
        options
      };
      const modal = await this.modalController.create({
        component: N7ModalFormComponent,
        componentProps: {
          titleLabel: this.translate.instant('i18n.task.property.dream_document_id.title', {}),
          formItems: [valueFormItem],
          dismissLabel: 'Save'
        },
        cssClass: 'modal-form modal-form-large'
      });

      modal.onDidDismiss().then((data) => {
        if (data !== null) {
          if (data.data && data.data.formItems && data.data.formItems[0]) {
            const itemValue = data.data.formItems[0].value;
            // console.warn('taskDocumentReferenceChange', itemValue);
            this.taskDocumentReferenceChange.emit(itemValue);
          }
        }
      }).catch((error) => {
        console.log('Error', error);
      });

      return await modal.present();
    });

    // const valueFormItem = {
    //   type: 'number',
    //   label: '',
    //   placeholder: 'Type the document ID...',
    //   unit: '',
    //   value: '',
    //   hint: ''
    // };
    // const modal = await this.modalController.create({
    //   component: N7ModalFormComponent,
    //   componentProps: {
    //     titleLabel: this.translate.instant('i18n.task.property.dream_document_id.title', {}),
    //     formItems: [valueFormItem],
    //     dismissLabel: 'Save'
    //   },
    //   cssClass: 'modal-form'
    // });

    // modal.onDidDismiss().then((data) => {
    //   if (data !== null) {
    //     if (data.data && data.data.formItems && data.data.formItems[0] && data.data.formItems[0].value && data.data.formItems[0].value.length) {
    //       const itemValue = data.data.formItems[0].value;
    //       // console.warn('TODO', itemValue);
    //       this.taskDocumentReferenceChange.emit(itemValue);
    //     }
    //   }
    // }).catch((error) => {
    //   console.log('Error', error);
    // });

    // return await modal.present();
  }
}
